.m-verslag .content {
    flex: auto 0 1;
    padding: 2rem 2rem 4rem 2rem;

    @media screen and (min-width: 1000px){
        .columns {
            columns: 2;
        }
    }
    @media screen and (max-width: 999px){
        .columns {
            columns: 2;
        }
    }
    @media screen and (max-width: 768px){
        .columns {
            columns: 1;
        }

        padding: 1rem 1rem 2rem 1rem;
    }
}

.m-verslag .vermogen {
    // margin-bottom: 7rem;
    position: relative;

    .polygon {
        width: 100%;
        object-fit: cover;
        position: relative ;
        display: flex;

        img {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 90%);

            width: 100%;
            height: 100%;
            object-fit: cover;
            flex-grow: 1;
        }
    }
 
    .balls { 
        margin-right: 1rem;
        justify-content: space-around;

        .item {
            text-align: center;
            font-family: acumin-pro, sans-serif;
            font-weight: 600;
            font-size: 1.5rem;
            &::after {
                content: '';
                border-right: 1px dotted gold;;
                height: 2rem;
                display: block;
                margin: 1rem auto;
            }
        }

        @media screen and (max-width: 768px){
            flex-wrap: wrap;
        }

        .ball {
            width: 150px;
            height: 150px;
            border-radius: 50%;
            background: $yellow;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 2rem;
            margin-bottom: 1rem;
            // &::after {
            //     content: '%';
            //     font-size: 1rem;
            //     margin-top: 10px;
            // }


            @media screen and (max-width: 768px){
                width: 100px;
                height: 100px;
                font-size: 1.2rem;
                margin-bottom: 0.5rem;
            }
        }

        
    }
}

// .m-verslag .bg-2022 {
//     background: url('/images/2022-bg-2.jpg') no-repeat right center;
//     background-size: cover;
//     padding: 1rem;
// }