.a-highlight {
    margin-top: 1rem;
    padding: 1rem;
    background-color: $gray-light;

    &--yellow {
        background: url('/images/diagram.png') no-repeat center top;
        background-size: cover;
        h4::after {
            display: none;
        }  
    }
    
}