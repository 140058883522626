.m-navigation-fixed {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
}

// .m-navigation-scrollcontainer {
//     flex-grow: 1;
//     flex-shrink: 1;
//     flex-basis: auto;
//     overflow-x: hidden;
// }


// .m-navigation-scrollable  {
//     /*transition: all 3s linear;*/
// }

// .m-navigation-scrollable ul {
//     display: flex;
//     justify-content: flex-start !important;
//     align-items: center;
// }

// .m-navigation-scrollable ul li.active a {
//     font-family: 'Tiempos Text Bold';
// }

// .m-navigation-scrollable ul li a {
//     position: relative;
// }

// .m-navigation-scrollable ul li a::after {
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     right: 0;
//     display: block;
//     content: "";
//     border-bottom: solid 3px $gray;
//     transform: scaleX(0);
//     transition: transform 250ms ease-in-out;
// }

// .m-navigation-scrollable ul li a:hover::after,
// .m-navigation-scrollable ul li.active a::after {
//     transform: scaleX(1);
// }

// .m-navigation-scrollable ul li.active a,
// .m-navigation-scrollable ul li a:hover {
//     background-color: $yellow;
// }
