body {
  font-size: 18px;
  line-height: 28px;
  font-family: "Tiempos Text";
}

.slides strong, .slides .strong {
  font-family: "Tiempos Text Bold";
}

@media screen and (max-width: 768px) {
  body {
    font-size: 16px;
    line-height: 24px;
  }
}

h1,
h2,
h3,
h4 {
  font-family: acumin-pro, sans-serif;
  font-weight: 700;
  font-style: normal;
}

.slides h2 {
  display: inline-block;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  position: relative;

  &::after {
    position: absolute;
    left: 20%;
    right: 20%;
    bottom: 0;
    top: 0;
    content: "";
    border-bottom: 2px solid $yellow;
  }
}

.slides h1 + h3 {
  margin-top: -0.2rem;
  margin-bottom: 2rem;
  font-weight: 200;
  font-size: 1.3rem;
}

.slides h3 {
  margin-bottom: 2rem;
}

.slides h4 {
  position: relative;
  z-index: 1;
  display: inline-block;
  font-size: 1.1rem;
  text-align: center;
  margin: 0 auto 1rem auto;
}

.slides h4::after {
  content: "";
  position: absolute;
  left: -10px;
  right: -10px;
  top: -5px;
  bottom: -7px;
  background: $yellow;
  z-index: -1;
}

.content ul {
  margin-left: 0;

  li {
    margin-left: 1rem;
    margin-bottom: 0.3rem;
    padding-left: 0;
  }
}

figcaption {
  font-size: 0.8rem;
  text-align: right;
  margin-top: 0.5rem;
  font-family: 'Tiempos Text Italic';
}