.m-kernactiviteiten .content {
  flex: auto 0 1;
  padding: 2rem 2rem 4rem 2rem;

  h1 {
    margin-bottom: 2rem;
  }

  @media screen and (min-width: 1000px) {
    .columns {
      columns: 2;
    }
  }
  @media screen and (max-width: 999px) {
    .columns {
      columns: 2;
    }
  }
  @media screen and (max-width: 768px) {
    .columns {
      columns: 1;
    }

    padding: 1rem 1rem 2rem 1rem;

    margin-bottom: 4rem;
  }

  h4 {
    margin-top: 1rem;
  }
}

.m-kernactiviteiten .polygon {
  clip-path: polygon(0 12%, 100% 0, 100% 100%, 0% 100%);
  // height: 500px;
  width: 100%;
  object-fit: cover;
  margin-bottom: 2rem;
}
