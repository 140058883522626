.a-quote {
    position: relative;
    padding: 10px 30px;
    z-index: 1;
    font-size:  1.4rem;
    line-height: 2.2rem;
    font-family: acumin-pro, sans-serif;
    font-weight: 700;
    font-style: normal;
    display: inline-block;

    .marked {
        display: inline;
        background: $yellow;
        padding: 5px 0;
        box-shadow: 10px 0 0 $yellow, -10px 0 0 $yellow;
        box-decoration-break: clone;
    } 

    span.quotemark {
        position: absolute;
        width: 20px;
        height: 20px;
        display: block;
        font-family: 'Tiempos Text';

        &--open {
            top: -1rem;
            left: 0;
            &::before {
                content: '“';
                font-size: 5rem;
            };
        }
        &--close {
            bottom: -3rem;
            right: 3rem;
            &::before {
                content: '”';
                font-size: 5rem;
            };
        }
    }

}