.m-slide.m-cliententevredenheid.active {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  min-height: 100vh;
  // @media screen and (max-width: 768px) {
  //   justify-content: flex-end;
  //   align-items: center;
  // }
}

.m-cliententevredenheid .background {
  min-height: 100vh;
  background: url("/images/2023-hoofdzetel-quiet.jpg") no-repeat bottom center;
  background-size: cover;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
}

.m-cliententevredenheid .content {
  background: rgba(255, 255, 255, 0.9);
  position: relative;
  z-index: 1;
  padding: 2rem;
  margin: 2rem 5% 4rem;
  flex: 70% 0 1;

  @media screen and (max-width: 1000px) {
    margin: 1rem 5% 5rem 5%;
    flex: 90% 0 1;
  }
  @media screen and (max-width: 768px) {
    margin: 1rem 5% 5rem 5%;
  }
  @media screen and (max-width: 500px) {
    max-width: 85%;
  }
}

.m-cliententevredenheid .columns {
  border-top: 1px solid $gray;
  padding-top: 1rem;
  columns: 2;
}
