.m-brick-and-click .content {
  flex: auto 0 1;
  padding: 2rem;

  h1 {
    margin-bottom: 2rem;
  }

  img {
    max-width: 100%;
  }

  @media screen and (min-width: 1000px) {
    .columns {
      columns: 2;
    }
  }
  @media screen and (max-width: 999px) {
    .columns {
      columns: 2;
    }
  }
  @media screen and (max-width: 768px) {
    .columns {
      columns: 1;
    }

    padding: 1rem 1rem 2rem 1rem;
  }
}

.m-brick-and-click .kantoren .polygon {
  clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%);
  height: 500px;
  width: 100%;
  object-fit: cover;
  position: relative;
}

.m-brick-and-click .kantoren blockquote {
  margin-top: 4rem;
  margin-bottom: 3rem;
  // position: absolute;
  // z-index: 5;
  // bottom: 2rem;
}

.m-brick-and-click .pictograms {
  img.picto {
    width: 22%;
    max-width: 150px;
    margin-right: 1%;
  }
}

.m-brick-and-click .splash {
  clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%);
  height: 100vh;
  background: url("/images/3d-printer-2.jpg") no-repeat right center;
  background-size: cover;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  @media screen and (max-width: 768px) {
    justify-content: center;
  }

  .splash__content {
    background: rgba(255, 255, 255, 0.9);
    position: relative;
    z-index: 1;
    padding: 2rem;
    margin: 2rem 10% 4rem;
    flex: 500px 0 1;
    @media screen and (max-width: 768px) {
      margin: 1rem 1rem 4rem;
      padding: 1rem;
    }
  }
}

.m-brick-and-click .personeel {
  display: flex;

  @media screen and (max-width: 600px) {
    display: block;
  }
  justify-content: flex-start;

  img.ballen {
    float: right;
    width: 30%;
    @media screen and (max-width: 1000px) {
      display: none;
    }
  }

  .sidebar {
    position: sticky;
    top: 0;

    flex: 0 1 400px;
    min-width: 200px;

    height: 100vh;

    img.tas {
      clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);

      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    img.ballen {
      position: absolute;
      bottom: 20px;
      right: -15px;
      width: 70%;
    }
  }
  @media screen and (max-width: 600px) {
    .sidebar {
      width: 100%;
      height: 350px;
      position: relative;
    }

    .sidebar img.ballen {
      right: 0;
      width: 250px;
    }
  }
}

.m-brick-and-click .box-sportbedrijf {
  position: relative;
  img.sportlabel {
    position: absolute;
    top: 0;
    left: -120px;
    width: 100px;

    @media screen and (max-width: 600px) {
      top: -45px;
      left: auto;
      right: 2rem;
      height: 100px;
      width: auto;
    }
    @media screen and (max-width: 450px) {
      display: none;
    }
  }
}

.m-brick-and-click .personeel .content {
  flex: auto 0 1;
  max-width: 968px;
  padding: 2rem 2rem 4rem 2rem;

  .columns {
    columns: 2;
  }

  @media screen and (max-width: 768px) {
    .columns {
      columns: 1;
    }
    padding: 1rem 1rem 2rem 1rem;
  }
}

.m-brick-and-click .organisatie .polygon {
  clip-path: polygon(0 45px, 100% 0, 100% 100%, 0% 100%);
  width: 100%;
  object-fit: cover;
  margin-bottom: 2rem;
  position: relative;
  padding: 5rem 2rem 2rem 2rem;
}
