.m-slide.m-connect.active {
    display: block;
    padding: 2rem;

    .box-diagram {
        background: url('/images/diagram.png') no-repeat right center;
        background-size: cover;
        padding: 2rem 1.5rem;
        clip-path: polygon(0 0, 100% 1rem, 100% 95%, 0% 105%);

        @media screen and (max-width: 768px) {
            clip-path: none;
        }

        h2::after {
            border-color: white;
        }
    }
}