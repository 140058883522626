.a-logo {

    border: none !important;

    a {
        background: url('/images/logo.png') no-repeat center center;
        width: 194px;
        margin-left: 15px;
        height: 30px;
        background-size: contain;
        display: block; 
        border: none !important;
    }
    
}  