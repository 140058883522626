@import "./00_cover";
@import "./01_crelan";
@import "./02_voorwoord";
@import "./03_verslag";
@import "./04_kernactiviteiten";
@import "./05_cliententevredenheid";
@import "./06_brickandclick";
@import "./07_esgbeleid";
@import "./08_personeel";
@import "./09_connect";
@import "./10_deugdelijkbestuur";
@import "./11_risicobeheer";
@import "./12_compliance";
@import "./13_jaarresultaten";
@import "./14_balans";
@import "./15_resultatenrekening";
@import "./16_kantoren";

body.overlay-active .m-slide {
    height: 100vh !important;
    overflow: hidden;
}