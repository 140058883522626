:root {
    --color-primary: #333;
    --color-yellow: #ffed06;
    --color-yellow-darker: #ffdd0095;
    --color-gray: #777;
    --color-gray-light: #e6e7e9;
    --color-green: #31bb5e;
    --color-white: #fff;
}

.m-slide.m-cover.active {
    display: flex;
}

.m-slide.m-cover {
    box-sizing: border-box;
    width: 100vw;
    height: calc(100vh - 3rem);
    border: solid 2rem white;
    padding: 2rem;
    justify-content: end;
    align-items: flex-end;
    background-color: $yellow;
    position: relative;
    z-index: 0;
    // background: url('/images/diagram.png') no-repeat center center;
    // background-size: cover;
}

.m-cover h1 {
    font-size: 4rem;
    position: relative;
    z-index: 2;
}

@media screen and (max-width: 767px) {
    .m-cover h1 {
        font-size: 2rem;
    }

    .m-slide.m-cover {
        justify-content: flex-start;
        align-items: start;
    }
}

.triangles-container {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    &::after {
        content: " ";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: calc(100vh - 3rem);
        border: solid 2rem white;

    }
}

.triangle {
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 1200px 7000px;
    border-color: transparent transparent #ffbb0082 transparent;
    /* animation: moveTriangle 15s infinite linear; */
    /* transition: all 10s ease-in-out; */
}

.triangle:nth-child(2) {
    border-color: transparent transparent var(--color-yellow-darker) transparent;
}

.triangle.active {
    /* transition: all 15s ease-in-out; */
    transition: all 15s ease-in-out;
}

@keyframes moveTriangle {
    0% {
        transform: translate(-50%, -50%) rotate(0);
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}