.m-slide.m-voorwoord.active {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.m-voorwoord .background {
  min-height: 100vh;
  background: url("/images/diagram.png") no-repeat left center;
  background-size: cover;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
}

.m-voorwoord .content {
  background: rgba(255, 255, 255, 0.9);
  position: relative;
  z-index: 1;
  padding: 2rem;
  margin: 2rem 2rem 5rem 5%;
  // flex: 700px 0 1;
  columns: 3;

  @media screen and (max-width: 1000px) {
    margin: 1rem;
    padding: 1rem;
    margin-bottom: 4rem;
    columns: 2;
  }

  @media screen and (max-width: 768px) {
    margin: 1rem;
    padding: 1rem;
    margin-bottom: 4rem;
    columns: 1;
  }

  @media screen and (max-width: 480px) {
    margin: 1rem;
    padding: 1rem;
    margin-bottom: 4rem;
  }
}

// .m-voorwoord .picture {
//   position: sticky;
//   top: 2rem;
//   z-index: 1;
//   background: url("/images/2022-atrium-ps.jpg") no-repeat 30% center;
//   background-size: cover;
//   height: 92.5vh;
//   flex: 30% 1 1;
//   max-width: 1100px;
//   margin: 2rem 5% 2rem 0;
//   @media screen and (max-width: 1250px) {
//     display: none;
//   }
// }

.m-voorwoord .columns {
  border-top: 1px solid $gray;
  padding-top: 1rem;
  columns: 2;
}

.m-voorwoord blockquote {
  margin: 3rem 0;
}

.m-voorwoord .avatar {
    width: 8rem;
    border-radius: 50%;
    margin-right: 1rem;
}

.m-voorwoord .clearfix::after {
  clear: both;

}
