.m-slide.m-jaarresultaten.active {
  display: flex;
}

.m-jaarresultaten .sidebar {
  position: fixed;
  z-index: 0;
  top: 0;
  right: 10px;

  width: 300px;

  height: 100vh;
  padding-top: 2rem;

  img {
    clip-path: polygon(0 5%, 100% 0, 100% 100%, 0% 100%);

    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: right center;
  }

  @media screen and (max-width: 1600px) {
    width: 250px;
  }

  @media screen and (max-width: 1350px) {
    width: 220px;
  }

  @media screen and (max-width: 1200px) {
    img {
      display: none;
    }
  }
}

.m-jaarresultaten .content {
  position: relative;
  z-index: 1;
  padding: 2rem 2rem 4rem 2rem;

  @media screen and (min-width: 1000px) {
    .columns {
      columns: 3;
    }
  }
  @media screen and (max-width: 999px) {
    .columns {
      columns: 2;
    }
  }
  @media screen and (max-width: 768px) {
    .columns {
      columns: 1;
    }

    padding: 1rem 1rem 2rem 1rem;
    margin-bottom: 3rem;
  }

  ul {
    margin-bottom: 2rem;
  }

  .jaarresultaat {
    margin-bottom: 2rem;
  }

  .m-table {
    position: sticky;
    top: 1rem;
  }

  .m-table tr td:first-child {
    width: 44%;
  }
}
