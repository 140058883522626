.a-verslag {
    position: absolute;
    z-index: 101;
    left: 0;
    top: 0;
    padding: 0;
    background: $yellow;
    color: black;
    transform: rotate(270deg);
    transform-origin: right bottom;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    h1 {
        font-size: 1rem;
        line-height: 0.8rem;

        span {
            display: block;
            font-weight: 200;
            font-size: 0.7rem;
        }
    }
} 

