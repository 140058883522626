.m-table {
  font-family: Helvetica, Arial, sans-serif;
  overflow-x: auto;
  margin-bottom: 2rem;
  padding: 0 1px;
  table {
    font-size: 0.8rem;
    width: 100%;
  }

  tr.--bottom-margin td {
    padding-bottom: 1rem;
  }

  tr.--bottom-border td {
    border-bottom: 1px solid #d1d1d1;
  }

  tr.--strong td,
  th {
    //font-family: 'Tiempos Text Bold';
    font-weight: 600;
  }

  th,
  td {
    padding: 0.3rem 0.5rem 0.15rem 0.5rem;
    line-height: 1rem;
    vertical-align: middle;
  }

  th:nth-last-child(2) {
    border: 1px solid #d1d1d1;
    background: #f9f9f9;
    border-bottom: none;
    border-right: none;
    text-align: right;

    width: 120px;
  }

  th:last-child {
    border: 1px solid #f7e774;
    background: #fff69b;
    text-align: right;
    width: 120px;
  }
  td:last-child {
    border-right: 1px solid #f7e774;
    text-align: right;
  }
  td:nth-last-child(2) {
    background: #fcfcfc;
    text-align: right;
  }

  tr:last-child td:last-child {
    border-bottom: 1px solid #f7e774;
  }

  tbody {
    background: #fff;
    border: 1px solid #d1d1d1;
  }

  tbody td:first-child {
    border-right: 1px solid #d1d1d1;
  }
  tbody td:last-child {
    border-left: 1px solid #f7e774;
    background: #fffbdc;
  }

  th.placeholder {
    border: none;
    background: none;
  }

  // @media screen and (max-width: 768px){
  //   overflow-x: scroll;
  // }
}
