.o-navigation-bar {
    border-top: 1px solid $gray;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    background: white;
}


.o-navigation-bar ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.o-navigation-bar ul li {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    border-right: 1px solid $gray;
    list-style-type: none;
    max-height: 3rem;
}

.o-navigation-bar ul li a {
    padding: 1rem;
    font-size: 1rem;
    line-height: 1rem;
    display: block;
    color: $gray;
    text-decoration: none;
}

.o-navigation-bar ul li.a-tile a {
    font-size: 1.5rem;
}

@media screen and (max-width: 768px) {
    li.dd-toc {
        display: none !important;
    }    
}


@media screen and (max-width: 500px) {
    // #scroll-container {
    //     display: none;
    // }

    li.dd-toc {
        display: none !important;
    }

    // .m-navigation-fixed {
    //     width: 100%;
    // }

    .o-navigation-bar ul {
        justify-content: center;

        li {
            border: none;
        }
    }
}

.o-navigation-bar button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    padding: 1rem;
    font-size: 1rem;
    line-height: 1rem;
    display: block;
    color: $gray;
    text-decoration: none;

    &:hover {
        color: black;
        background-color: $yellow;
    }
}

.o-navigation-bar .tippy-content {
    padding: 0;
}

.o-navigation-bar ul.dropdown-tableofcontents {
    padding: 15px;
    list-style: none;
    display: block;
    max-height: 500px;

    // if screen is less than 500px, the dropdown will be full screen
    @media screen and (max-height: 500px) {
        max-height: calc(100vh - 4rem);
    }

    overflow-y: auto;

    li {
        border: none;

        a {
            padding: 0.7rem 0.3rem;
            color: white;

            &:hover {
                color: $yellow;
            }
        }

        &.active a {
            color: $yellow;
        }
    }
    

}