.m-slide.m-balans.active {
  display: block;
}

.m-balans .sidebar {
  position: fixed;
  z-index: 0;
  top: 0;
  right: 0;

  width: 600px;

  height: 100vh;
  padding-top: 2rem;

  img {
    clip-path: polygon(0 5%, 100% 0, 100% 100%, 0% 100%);

    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: 20% right;
  }

  @media screen and (max-width: 1600px) {
    width: 600px;
  }

  @media screen and (max-width: 1350px) {
    width: 500px;
  }

  @media screen and (max-width: 1200px) {
    img {
      display: none;
    }
  }
}

.m-balans .content {
  position: relative;
  z-index: 1;
  padding: 2rem 2rem 4rem 2rem;

  @media screen and (min-width: 1000px) {
    .columns {
      columns: 3;
    }
  }
  @media screen and (max-width: 999px) {
    .columns {
      columns: 2;
    }
  }
  @media screen and (max-width: 768px) {
    .columns {
      columns: 1;
    }

    padding: 1rem 1rem 2rem 1rem;
    margin-bottom: 3rem;

    .m-table {
      overflow-x: hidden;
      margin-left: -15px;
      margin-right: -15px;

      table {
        width: 100%;
        font-size: 0.75rem;
      }

      .previous-year span,
      .current-year span {
        display: block;
      }
    }

    .m-table tr td:first-child {
      width: 40%;
    }

    .m-table tr:last-child td {
      padding-bottom: 15px;
    }
  }

  ul {
    margin-bottom: 2rem;
  }
}
