.o-tiles {
    position: absolute;
    z-index: 9999;
    background: #ffee06e7;
    width: 100vw;
    min-height: 100vh;
    top: 0;
    border: 2rem solid white;
    display: flex;
    justify-content: space-evenly;
    align-content: flex-start;
    flex-wrap: wrap;
}

.o-tiles.hidden {
    display: none;
}

.o-tiles .a-close {
    position: fixed;
    right: 0.5rem;
    top: 0.5rem;
    font-size: 2rem;
    &:hover {
        opacity: 0.5;
        cursor: pointer;
    }
}