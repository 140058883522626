.m-kantoren .content {
    display: flex;
    padding: 2rem 0 4rem 2rem;
    height: 100vh;
    flex-direction: column;
    justify-content: flex-end;

    @media screen and (min-width: 1000px) {
        .columns {
            columns: 3;
        }
    }

    @media screen and (max-width: 999px) {
        .columns {
            columns: 2;
        }
    }

    @media screen and (max-width: 768px) {
        .columns {
            columns: 1;
        }
    }
}

.m-kantoren .video {
    width: 30%;
    max-width: 500px;
    min-width: 350px;
    @media screen and (max-width: 768px) {
       display: none;
    }
}

.a-marker {
    background: url('/images/marker.png');
}

#locator-map {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 0;

    .overlay {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        // add filter to make the map darker
        fill: rgba(100, 98, 77, 0.578);
        transition: all 0.3s ease;
    }
}

.leaflet-popup-content {
    width: 300px;
    padding: 0;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    background: transparent;

    @media screen and (max-width: 500px) {
        width: auto;
    }
}

.locator_popup {
    position: relative;
    z-index: 9999;
    min-height: 200px;
    padding: 15px;
    color: #575757;
}

.locator_popup .title {
    font-size: 13px;
    font-family: "acumin-pro", Arial, Helvetica, sans-serif;
    line-height: 1.46;
    color: #575757
}

.locator_popup .subtitle {
    font-size: 16px;
    line-height: 1.46;
    color: #333;
    font-family: "acumin-pro", Arial, Helvetica, sans-serif;
    font-weight: 600;
    margin-bottom: 20px
}

.locator_popup .content_left,
.locator_popup .content_right {
    float: left;
    display: block;
    line-height: 1.3;
    font-family: "TiemposText", "Times New Roman", Times, serif;

    @media screen and (max-width: 500px) {
       float: none;
       margin-bottom: 1rem;
       width: auto !important;
    }
}

.locator_popup .content_left a,
.locator_popup .content_right a {
    color: #4a90e2;
    text-decoration: none
}

.locator_popup .content_left {
    width: 60%
}

.locator_popup .content_right {
    width: 40%
}

.locator_popup .content_right strong{
    font-weight: 600;
    font-family: "acumin-pro", Arial, Helvetica, sans-serif
}

