.m-tile {
    flex: 0 1 30%;
    padding: 1rem;
    text-align: center;
    cursor: pointer;

    img {
        width: 100%;
    }

    h3 {
        margin: 0.5rem 0;
        font-size: 1rem;
    }

    &:hover {
        img {
            box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.5);
        }
    }
}



@media screen and (max-width: 700px){
    .m-tile {
        flex: 0 1 50%;
    }
}
@media screen and (max-width: 500px){
    .m-tile {
        flex: 0 1 100%;
    }
}