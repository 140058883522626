.m-esg-beleid {
  img {
    max-width: 100%;
  }

  .polygon.digiday img {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 90%);
    height: 100%;
    width: 100%;
    object-fit: cover;
  
  }
}

.m-esg-beleid .content {
  flex: auto 0 1;
  padding: 2rem 0 0 2rem;

}

.m-esg-beleid .splash {
  clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%);
  min-height: 100vh;
  background: url("/images/diagram.png") no-repeat right center;
  background-size: cover;

  @media screen and (max-width: 1200px) {
    clip-path: none;
  }

  @media screen and (max-width: 768px) {
    // remove clip-path for mobile
    clip-path: none;
    min-height: auto;
  }

  h1 {
    padding: 10% 2rem 2rem 10%;
  }
  
  .box {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 4rem;
    // align-items: flex-end;
  
    @media screen and (max-width: 768px) {
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding: 1rem 1rem 4rem;
    }
  }
  

  .splash__content {
    background: rgba(255, 255, 255, 0.9);
    position: relative;
    z-index: 1;
    padding: 2rem;
    margin: 0rem 1rem 0 10%;
    flex: 500px 0 1;
    @media screen and (max-width: 768px) {
      padding: 1rem;
      margin: 1rem;
      flex: auto 0 1;
    }
  }

  .goals-tldr {
    position: sticky;
    top: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    gap: 5px;
    max-width: 400px;
    img {
      width: 75px;
    }

    @media screen and (max-width: 768px) {
      display: none;
    }
  }
}

.m-esg-beleid .goals {

  padding: 2rem 0 0 2rem;
  
  ul li {
    /* make bullets yellow */
    list-style: none;
    position: relative;
    padding-left: 1.5rem;
    margin-bottom: 1rem;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 8px;
      width: 7px;
      height: 7px;
      background: $yellow;
      /* use round bullets */
      border-radius: 50%;

    }

  }

  .row-goal {
    &::after {
      // set yellow line between goals
      content: "";
      display: block;
      width: 40%;
      height: 1.4px;
      background: $yellow;
      margin: 1rem auto 2rem;
    }
  }

  .m-goals {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;

    @media screen and (max-width: 768px) {
      flex-direction: row;
      justify-content: center;
      gap: 1rem;
    }

    img {
      display: none;
      max-width: 60px;
      margin-bottom: 0.5rem;
      
      &.show {
        display: block;
      }

      &.smaller {
        max-width: 40px;      
      }

    }
  }

  .sportbedrijf {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;

    img {
      height: 200px;
      display: block;
      clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
    }
  }
  .box-diagram {
    background: url("/images/diagram.png") no-repeat right center;
    background-size: cover;
    clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%);
    padding: 3rem 2rem 1rem 2rem;
    position: relative;
    z-index: 2;
    width: 70%;
  }


}

.m-esg-beleid .personeel {
  display: flex;

  @media screen and (max-width: 600px) {
    display: block;
  }
  justify-content: flex-start;

  img.ballen {
    float: right;
    width: 30%;
    @media screen and (max-width: 1000px) {
      display: none;
    }
  }

  .sidebar {
    position: sticky;
    top: 0;

    flex: 0 1 400px;
    min-width: 200px;

    height: 100vh;

    img.tas {
      clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);

      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    img.ballen {
      position: absolute;
      bottom: 20px;
      right: -15px;
      width: 70%;
    }
  }
  @media screen and (max-width: 600px) {
    .sidebar {
      width: 100%;
      height: 350px;
      position: relative;
    }

    .sidebar img.ballen {
      right: 0;
      width: 250px;
    }
  }
}

.m-esg-beleid .box-sportbedrijf {
  position: relative;
  img.sportlabel {
    position: absolute;
    top: 0;
    left: -120px;
    width: 100px;

    @media screen and (max-width: 600px) {
      top: -45px;
      left: auto;
      right: 2rem;
      height: 100px;
      width: auto;
    }
    @media screen and (max-width: 450px) {
      display: none;
    }
  }
}

.m-esg-beleid .voka-charter {
  display: flex;
  align-items: flex-end;
  .box-diagram {
    background: url("/images/diagram.png") no-repeat right center;
    background-size: cover;
    clip-path: polygon(0 0, 100% 10%, 100% 100%, 0% 95%);
    padding: 3rem 2rem 1rem 2rem;
    position: relative;
    z-index: 2;
    width: 60%;
  }

  img {
    display: block;
    width: 40%;
    clip-path: polygon(0 0, 100% 0, 95% 95%, 0% 100%);
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    .box-diagram {
      width: 100%;
      // remove clip-path for mobile
      clip-path: none;
    }
    img {
      width: 100%;
    }
  }
}

.m-esg-beleid .wetgeving {
  .columns {
    columns: 2;
  }

  @media screen and (max-width: 768px) {
    .columns {
      columns: 1;
    }
  }
}

.m-esg-beleid .personeel .content {
  flex: auto 0 1;
  max-width: 968px;
  padding: 2rem 2rem 4rem 2rem;

  .columns {
    columns: 2;
  }

  @media screen and (max-width: 768px) {
    .columns {
      columns: 1;
    }
    padding: 1rem 1rem 2rem 1rem;
  }
}

.m-esg-beleid .organisatie .polygon {
  clip-path: polygon(0 45px, 100% 0, 100% 100%, 0% 100%);
  width: 100%;
  object-fit: cover;
  margin-bottom: 2rem;
  position: relative;
  padding: 5rem 2rem 2rem 2rem;
}
