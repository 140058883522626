// .m-brick-and-click .content {
//     flex: auto 0 1;
//     padding: 2rem;

//     h1 {
//         margin-bottom: 2rem;
//     }

//     img {
//         max-width: 100%;
//     }

//     @media screen and (min-width: 1000px){
//         .columns {
//             columns: 2;
//         }
//     }
//     @media screen and (max-width: 999px){
//         .columns {
//             columns: 2;
//         }
//     }
//     @media screen and (max-width: 768px){
//         .columns {
//             columns: 1;
//         }

//         padding: 1rem 1rem 2rem 1rem;
//     }
// }

.m-compliance > div {
  display: flex;

  @media screen and (max-width: 600px) {
    display: block;
  }
  justify-content: flex-start;

  .sidebar {
    position: sticky;
    top: 0;

    flex: 0 1 400px;
    min-width: 200px;

    height: 100vh;

    img {
      clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);

      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  @media screen and (max-width: 600px) {
    .sidebar {
      width: 100%;
      height: 350px;
      position: relative;
    }
  }
}

.m-compliance > div .content {
  flex: auto 0 1;
  max-width: 968px;
  padding: 2rem 2rem 4rem 2rem;

  .columns {
    columns: 2;
  }

  @media screen and (max-width: 768px) {
    .columns {
      columns: 1;
    }
    padding: 1rem 1rem 2rem 1rem;

    margin-bottom: 2rem;
  }
}
