.m-slide.m-resultatenrekening.active {
  display: block;
}

.m-resultatenrekening .sidebar {
  position: fixed;
  z-index: 0;
  top: 0;
  right: 0;

  width: 750px;

  height: 100vh;
  padding-top: 2rem;

  img {
    clip-path: polygon(0 5%, 100% 0, 100% 100%, 0% 100%);

    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
  }

  @media screen and (max-width: 1600px) {
    width: 550px;
  }

  @media screen and (max-width: 1350px) {
    width: 500px;
  }

  @media screen and (max-width: 1200px) {
    img {
      display: none;
    }
  }
}

.m-resultatenrekening .content {
  position: relative;
  z-index: 1;
  padding: 2rem 2rem 4rem 2rem;

  @media screen and (max-width: 768px) {
    .m-table {
      overflow-x: hidden;
      margin-left: -25px;
      margin-right: -25px;

      table {
        width: 100%;
        font-size: 0.75rem;
      }

      .previous-year span,
      .current-year span {
        display: block;
      }
    }

    .m-table tr td:first-child {
      min-width: 40%;
    }

    .m-table tr:last-child td {
      padding-bottom: 15px;
    }
  }
}
