.m-slide.m-personeel.active {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.m-personeel .background {
  min-height: 100vh;
  background: url("/images/diagram.png") no-repeat left center;
  background-size: cover;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
}

.m-personeel .content {
  background: rgba(255, 255, 255, 0.9);
  position: relative;
  z-index: 1;
  padding: 2rem;
  margin: 2rem 2rem 5rem 5%;
  // flex: 700px 0 1;

  @media screen and (max-width: 768px) {
   margin-right: calc(5% - 10px);
  }

  .columns {
    columns: 2;

    @media screen and (max-width: 1200px) {
      columns: 1;
    }

    @media screen and (max-width: 768px) {
      margin: 0;
      padding: 0;
      margin-bottom: 4rem;
      columns: 1;
    }
  }
}

.m-personeel blockquote {
  margin: 3rem 0;
}

.m-personeel .avatar {
  width: 8rem;
  border-radius: 50%;
  margin-right: 1rem;
}

.m-personeel .clearfix::after {
  clear: both;

}

.m-personeel #personeel-chart {
  max-width: 100%;
}