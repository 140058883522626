.m-deugdelijk-bestuur .content {
  flex: auto 0 1;
  padding: 2rem;

  h1 {
    margin-bottom: 2rem;
  }

  img {
    max-width: 100%;
  }

  @media screen and (min-width: 1000px) {
    .columns {
      columns: 2;
    }
  }
  @media screen and (max-width: 999px) {
    .columns {
      columns: 2;
    }
  }
  @media screen and (max-width: 768px) {
    .columns {
      columns: 1;
    }

    padding: 1rem 1rem 2rem 1rem;
  }
}

.m-deugdelijk-bestuur .polygon-boardmembers {
  clip-path: polygon(0 4%, 100% 0, 100% 100%, 0% 98%);
  margin-bottom: -1rem;
  position: relative;

  #overlay-tippy-members {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;

    .mdi {
      position: absolute;
      right: 1rem;
      top: 1rem;
      font-size: 2rem;
      color: $yellow;

      &:hover {
        /* stop animation */
        animation-play-state: paused !important;
      }
    }

    .mdi:hover ~ .tippy-tag {
      border: 1px dashed rgba(255, 255, 0, 0.571);
    }

    .tippy-tag {
      width: 8%;
      aspect-ratio: 1;
      position: absolute;
      border: none;
      padding: 0;
      background: none;

      &:hover {
        border: 1px dashed rgba(255, 255, 0, 0.571);
      }
    }

    #luc { top: 7%; left: 26%;}
    #gerald { top: 8%; left: 35% }
    #joris { top: 7%; left: 47%; }
    #jean-paul { top: 7%; left: 60%; }
    #clair { top: 14%; left: 20%; }
    #paul { top: 17%; left: 44%; }
    #xavier { top: 11%; left: 67%; }
    #sophie { top: 22%; left: 23%; }
    #grete { top: 20%; left: 69%; }
    #marc { top: 22%; left: 80%; width: 9%; }
    #agnes { top: 29%; left: 15%; width: 9%; }


  }
}

.m-deugdelijk-bestuur {
  .sticky {
    position: sticky;
    top: 1rem;
    z-index: 1;
  }
}

.m-deugdelijk-bestuur .highlight {
  margin-top: 0;
  margin-bottom: 1rem;
  padding: 2rem 1rem;
  background-color: $gray-light;
  font-size: 0.9rem;
  h3 {
    font-size: 1.2em;
  }
  // max-width: 500px;
}

.m-deugdelijk-bestuur table {
  text-align: left;
  border: none;
  background: none;

  * {
    background: none !important;
  }
  th,
  td {
    padding: 0 0.75rem 0.75rem 0;
    border: none;
    font-size: 0.9rem;
  }

  th {
    // width: 130px;
    font-family: "Tiempos Text Medium";
  }
}

.m-deugdelijk-bestuur .table-desktop {
  @media screen and (max-width: 1500px) {
    display: none;
  }
}

.m-deugdelijk-bestuur .table-mobile {
  th {
    padding: 0;
  }

  @media screen and (min-width: 1499px) {
    display: none;
  }
}

.m-deugdelijk-bestuur #directiecomite {
  margin-top: 1rem;
}
