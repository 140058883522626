.m-slide.m-crelan.active {
  display: flex;

  @media screen and (max-width: 600px) {
    display: block;
  }
}

.m-slide.m-crelan {
  justify-content: flex-start;
}

.m-crelan {

  .sticky {
    position: sticky;
    top: 1rem;
  }

  .sidebar {
    position: sticky;
    top: 0;

    flex: 0 1 400px;
    min-width: 250px;

    height: 100vh;
    padding-top: 2rem;

    img {
      clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%);

      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: left center;
    }
  }
  @media screen and (max-width: 600px) {
    .sidebar {
      width: 100%;
      height: 350px;
      position: relative;
    }
  }
}

.m-crelan .content {
  flex: auto 0 1;
  max-width: 1201px;
  padding: 2rem 2rem 4rem 2rem;

  .columns {
    columns: 1;

    p {
      break-inside: initial;
      display: inline-block;
    }
  }

  @media screen and (max-width: 768px) {
    .columns {
      columns: 1;
    }
    padding: 1rem 1rem 2rem 1rem;
  }
} 
 
.m-crelan .highlight {
  margin-top: 4rem;
  padding: 1rem;
  background-color: $gray-light;
  @media screen and (max-width: 768px) {
    margin-bottom: 4rem;
  }

  .avatar {
    float: right;
    margin-top: -5rem;
    width: 10rem;
    img {
      width: 100%;
      border-radius: 50%;
    }
  }
}
 
.m-crelan .tag {
  margin: 2rem 0; 
  font-size: 1.5rem;
  position: absolute;
  bottom: 2rem;
  left: 1rem;
}

.m-crelan img.polygon {
  clip-path: polygon(10% 0, 100% 0, 100% 100%, 0% 100%);
}