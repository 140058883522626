*, ul, ol {
    margin: 0;
    padding: 0;
}

body {
    // overflow: hidden;
    // height: 100vh;
}

#slides {
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100vh - 50px);
}
