.m-risicobeheer {
  margin-bottom: 4rem;
  .content {
    flex: auto 0 1;
    padding: 2rem;

    h1 {
      margin-bottom: 2rem;
    }

    img {
      max-width: 100%;
    }

    @media screen and (min-width: 1200px) {
      .columns {
        columns: 3;
      }
    }
    @media screen and (max-width: 1199px) {
      .columns {
        columns: 2;
      }
    }
    @media screen and (max-width: 768px) {
      .columns {
        columns: 1;
      }

      padding: 1rem 1rem 2rem 1rem;
    }
  }
}

.m-risicobeheer .splash {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 97%);
  min-height: 100vh;
  background: url("/images/diagram.png") no-repeat right bottom;
  background-size: cover;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  @media screen and (max-width: 768px) {
    justify-content: center;

    .a-quote {
      max-width: 80%;
      padding: 2rem 0;
    }
  }

  .splash__content {
    background: rgba(255, 255, 255, 0.9);
    position: relative;
    z-index: 1;
    padding: 2rem;
    margin: 2rem 10% 4rem;
    flex: 900px 0 1;
    @media screen and (max-width: 768px) {
      margin: 1rem 1rem 4rem;
      padding: 1rem;
    }
  }
}

.m-risicobeheer #concentratierisico .polygon {
  clip-path: polygon(0 45px, 100% 0, 100% 100%, 0% 100%);
  width: 100%;
}
