$mdi-font-path: '/fonts/@mdi';


@import "~bootstrap/scss/bootstrap";
@import "~@mdi/font/scss/materialdesignicons.scss";
@import "~animate.css/animate.min.css";

 
// Import Globals
// ------------------------------------------------------------------------- /
@import "./globals/_index.scss";

// Import Quarks
// ------------------------------------------------------------------------- /
@import "./quarks/_index.scss";
 
// Import Atoms
// ------------------------------------------------------------------------- /
@import "./atoms/_index.scss";

// Import Molecules
// ------------------------------------------------------------------------- /
@import "./molecules/_index.scss";

// Import Organisms
// ------------------------------------------------------------------------- /
@import "./organisms/_index.scss";


// Import Full Slides
// ------------------------------------------------------------------------- /
@import "./slides/_index.scss";