// .m-arrows {
//     .a-arrow {
//         position: fixed;
//         top: 50vh;
//         width: 5rem;
//         margin-top: -1.5rem;
//         font-size: 3rem;
//         display: block;
//         color: black;
//         opacity: 0.3;
//         z-index: 100;
//         transition: all ease-in-out 0.3s;

//         i {
//             pointer-events: none;
//             text-shadow: -1px -1px 0 #FFF, 1px -1px 0 #FFF, -1px 1px 0 #FFF, 1px 1px 0 #FFF;
//         }
//     }

//     #previous {
//         left: 0;
//         text-align: left;
//         margin-left: -30px;
//     }

//     #next {
//         right: 0;
//         text-align: right;
//         margin-right: -28px;
//     }
// }


// .no-touch #previous:hover {
//     opacity: 1;
//     margin-left: 0ch;

//     color: $yellow;
// }

// .no-touch #next:hover {
//     opacity: 1;
//     margin-right: 0;

//     color: $yellow;
// }

.a-arrow {
    i {
        transition: all ease-in-out 0.3s;
        display: block;
        
    }
    transition: all ease-in-out 0.3s;
    
    &:hover {
        opacity: 1;
        color: $yellow;
    }

    &#previous {
        &:hover {
          i {
            transform: translateX(-5px)
          }
        }
    }

    &#next {
        &:hover {
          i {
            transform: translateX(5px)
          }
        }
    }
    
}

